import React from "react";
import Layout from "../components/layout/Layout";
import { Button } from "../components/Button";

export default ({ location }) => {
    return (
        <Layout title="Thanks for the Trial registration!" location={location}>
            <main id="content">
                <section className="hero is-fullheight-with-navbar">
                    <div className="hero-body">
                        <div className="container content">
                            <div className="columns">
                                <div className="column is-9 is-offset-2">
                                    <h1>Thank you for registering for your 30 day Frends Trial.</h1>
                                    <p>The Frends team will process your request and will get back to you with your login details within one working day.
                                    If you do not see incoming emails from Frends, we recommend to white-list <a href="mailto:support@frends.com">support@frends.com</a>, or check your Spam folder, otherwise the email with your login details might end up in the junk mail.
                                    For any inquiries regarding the trials, feel free to reach <a href="marketing@frends.com">marketing@frends.com</a></p>
                                    <Button color="blue" type="outlined" to="/" text="go back home" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}